import React from 'react'
import { Shared } from 'modules'
import {
  Main,
  Div,
  Span,
  Button,
  Image,
  multiMedia,
  Container,
  ENV
} from 'fractals'
import { useRouter } from 'next/router'
import { GetStaticProps } from 'next'

type TCustom404Props = {
  categories: Awaited<Promise<ReturnType<typeof Shared.getHeaderData>>>
  footer: Awaited<Promise<ReturnType<typeof Shared.getFooterData>>>
}

export const Custom404 = ({ categories, footer }: TCustom404Props) => {
  const router = useRouter()
  return (
    <Shared.PageContainer>
      <Shared.Header withBreadcrumbs={false} categories={categories} />
      <Main>
        <Container>
          <Div
            styles={{
              ...multiMedia({
                gap: '56px',
                tablet: { gap: '40px' },
                phone: { gap: '40px' }
              }),
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
            <Span variant='alpha'>
              Упс... <br /> Кажется тут что-то сломалось
            </Span>
            <Button
              onClick={() => router.push('/')}
              styles={{
                ...multiMedia({ width: '280px', phone: { maxWidth: '342px' } })
              }}
              sizeVariant='md'
              colorsVariant='beta'>
              На главную
            </Button>
            <Div
              styles={{
                position: 'relative',
                ...multiMedia({
                  marginBottom: '56px',
                  desktop: { width: '1212.009px', height: '426px' },
                  laptop: { width: '809.343px', height: '284.47px' },
                  tablet: {
                    width: '628.765px',
                    height: '221px',
                    marginBottom: '64px'
                  },
                  phone: { width: '340px', height: '119.504px' }
                })
              }}>
              <Image
                src='/assets/imgs/custom404/custom404.png'
                alt='custom 404'
                fill
                quality={75}
                containerProps={{
                  styles: {
                    '& img': {
                      objectFit: 'cover'
                    }
                  }
                }}
                colorVariant='transparent'
              />
            </Div>
          </Div>
        </Container>
        {/* <Shared.AskModal /> */}
      </Main>
      <Shared.Footer footer={footer} />
    </Shared.PageContainer>
  )
}

export const getStaticProps: GetStaticProps = async context => {
  try {
    const categories = await Shared.getHeaderData({ context })
    const footer = await Shared.getFooterData({ context })

    const props = {
      categories: categories || null,
      footer: footer || null
    }

    const revalidate = ENV.prod ? 60 * 60 * 24 : false // 1 day

    return {
      props,
      revalidate
    }
  } catch (err) {
    console.error(err)
  }

  return {
    props: {}
  }
}

export default Custom404
